var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailInfo",
              attrs: { title: "교육대상자 정보", collapsed: true },
            },
            [
              _c(
                "template",
                { slot: "card-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable && !_vm.disabled
                        ? _c("c-btn", {
                            attrs: {
                              url: _vm.saveUrl,
                              isSubmit: _vm.isSave,
                              param: _vm.tabParam,
                              mappingType: _vm.saveType,
                              label: "교육대상자/협력업체 직원 저장",
                              icon: "save",
                            },
                            on: {
                              beforeAction: _vm.saveEducation,
                              btnCallback: _vm.saveCallback,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "template",
                { slot: "card-detail" },
                _vm._l(_vm.items, function (item, idx) {
                  return _c("q-chip", {
                    key: idx,
                    attrs: {
                      outline: "",
                      square: "",
                      color: _vm.customColor(item, item.attendeesTypeCd),
                      "text-color": "white",
                      icon: "person_outline",
                      label: item.attendeesPositionName,
                      title: item.attendeesPositionName,
                    },
                  })
                }),
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-table",
            {
              ref: "table",
              attrs: {
                title: "부서/직책/법규선해임 교육대상자",
                columns: _vm.grid.columns,
                gridHeight: _vm.grid.height,
                data: _vm.grid.data,
                filtering: false,
                columnSetting: false,
                usePaging: false,
                merge: _vm.grid.merge,
                hideBottom: true,
                noDataLabel: "교육대상자가 없습니다.",
                editable: _vm.editable && !_vm.disabled,
                rowKey: "attendeesNo",
                selection: "multiple",
              },
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable && !_vm.disabled
                        ? _c("c-btn", {
                            attrs: {
                              label: "추가",
                              showLoading: false,
                              icon: "add",
                            },
                            on: {
                              btnClicked: function ($event) {
                                return _vm.addItem("DEPT")
                              },
                            },
                          })
                        : _vm._e(),
                      _vm.editable && !_vm.disabled && _vm.grid.data.length > 0
                        ? _c("c-btn", {
                            attrs: {
                              label: "삭제",
                              showLoading: false,
                              icon: "remove",
                            },
                            on: { btnClicked: _vm.removeItem },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-table",
            {
              ref: "table4",
              attrs: {
                title: "협력업체 직원",
                columns: _vm.grid2.columns,
                gridHeight: _vm.grid2.height,
                data: _vm.grid2.data,
                filtering: false,
                columnSetting: false,
                usePaging: false,
                hideBottom: true,
                noDataLabel: "교육대상자가 없습니다.",
                editable: _vm.editable && !_vm.disabled,
                rowKey: "attendeesNo",
                selection: "multiple",
              },
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable && !_vm.disabled
                        ? _c("c-btn", {
                            attrs: {
                              label: "추가",
                              showLoading: false,
                              icon: "add",
                            },
                            on: { btnClicked: _vm.addItem4 },
                          })
                        : _vm._e(),
                      _vm.editable && !_vm.disabled && _vm.grid2.data.length > 0
                        ? _c("c-btn", {
                            attrs: {
                              label: "삭제",
                              showLoading: false,
                              icon: "remove",
                            },
                            on: { btnClicked: _vm.removeItem4 },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }