<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card title="교육대상자 정보" :collapsed="true" class="cardClassDetailInfo">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn
              v-if="editable && !disabled"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="tabParam"
              :mappingType="saveType"
              label="교육대상자/협력업체 직원 저장"
              icon="save"
              @beforeAction="saveEducation"
              @btnCallback="saveCallback" 
            />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <q-chip outline square
            v-for="(item, idx) in items"
            :key="idx"
            :color="customColor(item, item.attendeesTypeCd)"
            text-color="white"
            icon="person_outline"
            :label="item.attendeesPositionName"
            :title="item.attendeesPositionName"
          >
          </q-chip>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table"
        title="부서/직책/법규선해임 교육대상자"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="grid.data"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :merge="grid.merge"
        :hideBottom="true"
        noDataLabel="교육대상자가 없습니다."
        :editable="editable && !disabled"
        rowKey="attendeesNo"
        selection="multiple"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable && !disabled" label="추가" :showLoading="false"  icon="add" @btnClicked="addItem('DEPT')" />
            <c-btn v-if="editable && !disabled && grid.data.length > 0" label="삭제" :showLoading="false" icon="remove" @btnClicked="removeItem" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table4"
        title="협력업체 직원"
        :columns="grid2.columns"
        :gridHeight="grid2.height"
        :data="grid2.data"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :hideBottom="true"
        noDataLabel="교육대상자가 없습니다."
        :editable="editable && !disabled"
        rowKey="attendeesNo"
        selection="multiple"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable && !disabled" label="추가" :showLoading="false"  icon="add" @btnClicked="addItem4" />
            <c-btn v-if="editable && !disabled && grid2.data.length > 0" label="삭제" :showLoading="false" icon="remove" @btnClicked="removeItem4" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'education-result-target-user',
  props: {
    tabParam: {
      type: Object,
      default: () => ({
        eduEducationId: '',
        eduCourseId: '',
        documentStatusCd: '',
        eduAttendeeList: [],
      }),
    },
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      grid: {
        merge: [
          { index: 0, colName: 'attendeesPositionName' },
        ],
        columns: [
          {
            name: 'attendeesDeptName',
            field: 'attendeesDeptName',
            style: 'width:100px',
            label: '부서',
            align: 'center',
            sortable: false,
          },
          {
            name: 'attendeesPositionName',
            field: 'attendeesPositionName',
            style: 'width:100px',
            label: '직책',
            align: 'center',
            sortable: false,
          },
          {
            name: 'seniorityName',
            field: 'seniorityName',
            style: 'width:100px',
            label: '법규/선해임',
            align: 'center',
            sortable: false,
          },
          {
            name: 'attendeesName',
            field: 'attendeesName',
            label: '대상자명',
            type: 'user',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
        ],
        data: [],
        height: '450px'
      },
      grid2: {
        columns: [
          {
            name: 'companyName',
            field: 'companyName',
            label: '소속회사',
            align: 'center',
            style: 'width:100px',
            type: 'text',
            sortable: false,
          },
          {
            name: 'attendeesPositionName',
            field: 'attendeesPositionName',
            style: 'width:100px',
            label: '직책',
            type: 'text',
            align: 'center',
            sortable: false,
          },
          {
            name: 'attendeesName',
            field: 'attendeesName',
            label: '대상자명',
            type: 'text',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
        ],
        data: [],
        height: '250px'
      },
      editable: true,
      getUrl: '',
      targetClassCd: '',
      updateMode: false,
      items: [],

      saveUrl: 'transactionConfig.sop.edu.plan.insert.url',
      saveType: 'POST',
      isSave: false,
      updateUrl: '',
      insertUrl: '',
    };
  },
  computed: {
    disabled() {
      return Boolean(this.tabParam.documentStatusCd) && this.tabParam.documentStatusCd !== 'ESC000001'
    },
  },
  watch: {
    'grid.data'() {
      this.tabParam.eduAttendeeList = (this.$_.concat(this.grid.data, this.grid2.data));
    },
    'grid2.data'() {
      this.tabParam.eduAttendeeList = (this.$_.concat(this.grid.data, this.grid2.data));
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.getUrl = selectConfig.sop.edu.attendee.get.url;
      this.insertUrl = transactionConfig.sop.edu.plan.insert.url;
      this.updateUrl = transactionConfig.sop.edu.plan.update.url;
      // code setting
      
      this.getDetail();
      // list setting
    },
    getDetail() {
      if (this.tabParam.eduEducationId) {
        this.grid.data = [];
        this.grid2.data = [];
        this.$_.forEach(this.tabParam.eduAttendeeList, _item => {
          if (_item.attendeesTypeCd === 'EATC00004') {
            this.grid2.data.push(_item) // 부서
          } else {
            this.grid.data.push(_item) // 선임
          }
        })
      }
      if (this.tabParam.eduCourseId) {
        this.$http.url = this.$format(this.getUrl, this.tabParam.eduCourseId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.items = _result.data
          this.updateMode = true;
        },);
      }
    },
    addItem(data) {
      if (data === 'DEPT') this.targetClassCd = '1';
      else this.targetClassCd = '4';

      if (this.targetClassCd === '4') {
        this.grid2.data.splice(0, 0, {
          eduCourseId: this.tabParam.eduCourseId,
          attendeesNo: uuidv4(),
          attendeesTypeCd: 'EATC00004',
          attendeesTypeName: '',
          attendeesPositionName: '',
          companyName: '',
          residentNo: '',
          attendeesName: '',
          educationCycle: '',
          editFlag: 'C'
        })
      } else {
        this.popupOptions.title = "교육대상자 검색"; // 교육대상자 검색
        this.popupOptions.param = {
          type: 'multiple',
          targetClassCd: this.targetClassCd,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/edu/result/educationCompletePop.vue'}`)
        this.popupOptions.visible = true;
        this.popupOptions.width = '100%';
        this.popupOptions.isFull = false;
        this.popupOptions.closeCallback = this.closeTargetUserPopup;
      }
    },
    closeTargetUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data.selectData && data.selectData.length > 0) {
        this.$_.forEach(data.selectData, _item => {
           let index = this.$_.findIndex(this.grid.data, {
            attendeesId: _item.userId,
          });
          // 부서 EATC00003
          if (index === -1) {
            this.grid.data.splice(0, 0, {
              eduCourseId: this.tabParam.eduCourseId,
              attendeesNo: uuidv4(),
              attendeesTypeCd: 'EATC00003',
              attendeesTypeName: '',
              attendeesPositionName: _item.jobName,
              attendeesDeptName: _item.deptName,
              seniorityName: _item.seniorityName,
              attendeesId: _item.userId,
              attendeesName: _item.userName,
              editFlag: 'C'
            })
          }
            // 직책 EATC00001
        })
      }
    },  
    removeItem() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.grid.data = this.$_.reject(this.grid.data, item)
        })
      }
    },
    addItem4() {
      this.grid2.data.splice(0, 0, {
        eduCourseId: this.tabParam.eduCourseId,
        attendeesNo: uuidv4(),
        attendeesTypeCd: 'EATC00004',
        attendeesPositionCd: '',
        attendeesPositionName: '',
        companyName: '',
        residentNo: '',
        attendeesName: '',
        editFlag: 'C'
      })
    },
    removeItem4() {
      let selectData = this.$refs['table4'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.grid2.data = this.$_.reject(this.grid2.data, item)
        })
      }
    },
    saveEducation() {
      if (this.tabParam.eduEducationId) {
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = 'POST';
      }
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '저장하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.tabParam.regUserId = this.$store.getters.user.userId
          this.tabParam.chgUserId = this.$store.getters.user.userId
          
          this.isSave = !this.isSave;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
    },
    innerBtnClicked4(col, props) {
      this.grid2.data.splice(props.rowIndex, 0, {
        key: props.row.key, 
        eduCourseId: this.tabParam.eduCourseId,
        attendeesNo: uuidv4(),
        attendeesTypeCd: 'EATC00004',
        attendeesPositionCd: '',
        attendeesPositionName: props.row.attendeesPositionName,
        attendeesName: '',
        companyName: props.row.companyName,
        residentNo: props.row.residentNo,
        editFlag: 'C',
      });
    },
    customColor(data, type) {
      if (type === 'EATC00001') {
        return 'primary';
      } else if (type === 'EATC00002') {
        return 'red';
      } else if (type === 'EATC00003') {
        return 'orange';
      } else {
        return 'green';
      }
    }
  }
};
</script>
